.container {
    display: flex;
    justify-content: center;
}

.page {
    display: flex;
    flex-direction: column;
    background-color: white;
    min-height: 100vh;
    width: 80%;
}

a.anchor {
    display: block;
    position: relative;
    top: -4em;
    visibility: hidden;
}

.divider {
    color: $gold;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.song-request {
    a {
        text-decoration: underline;
    }
}

.spinner {
    animation: spin infinite 5s linear;

    /*You can increase or decrease the timer (5s) to 
    increase or decrease the speed of the spinner*/
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.section {
    padding: 2em;

    .subtitle {
        font-family: 'Modern Line';
    }

    .title {
        width: 100%; 
        text-align: center; 
        border-bottom: 1px solid $gold; 
        line-height: 0.1em;
        margin: 10px 0 20px;

        span {
            background:#fff; 
            padding:0 20px;
        }
    }

    .button {
        border: 1px solid $gold;
        border-radius: 0.5em;
        width:fit-content;
        align-self: center;
        padding: 0.5em;

        &:hover {
            background-color: $gold;
            color: $white;
            cursor: pointer;
        }
    }

    form {
        border: 1px solid $gold;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2em;

        margin-bottom: 2em;

        div, button {
            margin-top: 2em;
        }

        div:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

    }

    &.home {
        margin-top: 4em;

        display: flex;
        flex-direction: column;
        align-items: center;

        text-align: center;

        .name-container {
            display: flex;
            flex-direction: column;
            width: 60%;

            .name {
                font-family: 'Boheme Floral';

                &:first-of-type {
                    margin-top: 0.5em;
                    margin-left: -1.5em;
                }

                &:last-of-type {
                    margin-top: -0.25em;
                    margin-bottom: 0.75em;
                    margin-right: -1.5em;
                }
            }
        }

        &.login {
            form {
                // width: 75%;

                img {
                    width: 30%;
                    margin: 2em 0;
                }
                label, input {
                    width: 75%;
                    margin-bottom: 1em;
                }

                input {
                    height: 1.5em;
                    margin-bottom: 2em;
                }
            }
        }
    }

    .detail-with-image {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 4em;

        img {
            width: 30%;
        }

        .text {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            width: 40%;
            text-align: center;
        }
    }

    

    .details {
        margin: 2em 0; 
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 1em;

        .details-container {
            display: flex;
            flex-direction: row;

            .details-section {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;

                .subtitle {
                    margin-bottom: 1em;
                }

                img {
                    width: 80%;
                }
            }
        }

        div {
            margin-bottom: 2em;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &.contact-us {
            border: 1px solid $gold;
        }
        
        &.date {
            position: relative;

            &:before {
                content: '';
                width: 100%;
                height: 1px;

                position: absolute;
                right: 0;
                top: -10%;

                background-color: $gold;
            }

            &:after {
                content: '';
                width: 100%;
                height: 1px;

                position: absolute;
                right: 0;
                top: 100%;

                background-color: $gold;
            }
        }

        &.rsvp-container {
            margin-bottom: 0;

            .form-container {
                margin-bottom: 0;
            }
        }

    }
}

@include phone {

    .spotify-container {
        display: none;
    }

    a.anchor {
        top: -6em;
    }

    .home.login {
        height: 100%;

        form {
            height: 100%;
            justify-content: center;

            input {
                width: 100%;
            }
        }
    }

    .section {
        font-size: 12px;
        margin: 2em 0;

        .name-container {
            :not(.name) {
                margin-bottom: 1em;
                margin-left: -2em;
            }

            .name {
                font-size: 56px;
            }
        }
    }

    .detail-with-image {
        flex-direction: column !important;
        align-items: center;
        img {
            margin-bottom: 4em;
        }

        .text {
            width: 100% !important;

            div {
                margin-bottom: 1em;
            }
        }
    }
}

@include tablet {
    a.anchor {
        top: -6em;
    }

    .section {

        &.home {
            margin-top: unset;

            &.login {
                height: 100%;
            }

            form {
                padding: unset;
                justify-content: center;
                height:100%;

                div:first-of-type {
                    margin-top: 2em;
                }
            }
        }
        font-size: 18px;
        .name-container {
            :not(.name) {
                margin-top: -1em;
                margin-bottom: 1em;
                margin-left: -2em;
            }

            .name {
                font-size: 120px;
            }
        }
    }

    .detail-with-image {
        flex-direction: column !important;
        align-items: center;
        img {
            margin-bottom: 4em;
        }

        .text {
            width: 100% !important;

            div {
                margin-bottom: 1em;
            }
        }
    }
}

@include desktop {
    a.anchor {
        top: -4em;
    }

    .section {
        font-size: 24px;
        .name-container {
            :not(.name) {
                margin-top: -1em;
                margin-bottom: 1em;
                margin-left: -2em;
            }

            .name {
                font-size: 200px;
            }
        }
    }
}