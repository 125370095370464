.menu {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-self: center;
    padding: 2em 0;
    position: fixed;
    width: 75%;
    background-color: $white;
    z-index: 1;

    &:after {
        content: '';
        width: 100%;
        height: 1px;

        position: absolute;
        right: 0;
        top: 100%;

        background-color: $gold;
    }
}

@include phone {
    .menu {
        font-size: 12px;
        width: 75%;
    }
}