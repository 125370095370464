@mixin phone {
    @media (max-width: 599px) { @content; }
}

@mixin tablet {
    @media (min-width: 600px) { @content; }
}

@mixin laptop {
    @media (min-width: 900px) { @content; }
}

@mixin desktop {
    @media (min-width: 1200px) { @content; }
}