@font-face {
    font-family: 'Boheme Floral';
    src: url('../fonts/Boheme\ Floral.ttf');
}

@font-face {
    font-family: 'Modern Line';
    src: url('../fonts/modernline.otf');
}

@font-face {
    font-family: 'Perfect Strangers';
    src: url('../fonts/PerfectStrangers_TTF.ttf');
}

body,html {
    margin: 0;
    padding: 0;
    height: 100%;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    background: url(../images/background.avif);
    background-size:cover;
    background-position: center;
    background-attachment: fixed;
    font-family: 'Perfect Strangers';
    color: $gold;
}

#root {
    height: 100%;
    min-width: 350px;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

a {
    color: inherit;
    text-decoration: inherit;
}